.custom-select-arrow .ant-select-arrow {
    color: black;
    font-size: 20px; /* Increase the size of the down arrow icon */
  }
  
  .custom-select-arrow .ant-select-selector {
    border-width: 3px; /* Control border size */
  }
  .apexcharts-toolbar{
    display: none;
  }